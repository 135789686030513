import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, getSrc } from "gatsby-plugin-image"

import LayoutLanding from "../components/layout-landing"
import Seo from "../components/seo"
import HeaderLanding from "../components/header/header-landing"
import HeroSection831bNewLanding from "../components/sections/hero-section-831b-new-landing"
import SectionNewLandingIntro from "../components/sections/section-new-landing-intro"
import SectionNewLandingBenefits from "../components/sections/section-new-landing-benefits"

const PageDentalLanding = () => {
  const data = useStaticQuery(graphql`
    query PageDentalLandingQuery {
      imgHero: file(relativePath: { eq: "backgrounds/dentist.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

const imgHero = getImage(data.imgHero)
const imgSrc = getSrc(data.imgHero)

  return (
    <LayoutLanding>
      <Seo
        title="SRA 831(b) Plan for Dentist, Orthodontist"
        description="Run your Own Dental Protection Plan for your patients. You Define your adaptable terms in your own Dental Protection plan."
        image={imgSrc}
        noIndex
      />

      <HeaderLanding />

      <HeroSection831bNewLanding
        title="How do you pay for rework? What if you could use a tax-efficient method? How to retain patients through a profitable warranty?"
        subTitle="Run your Own Dental Protection Plan for your patients. You Define your adaptable terms in your own Dental Protection plan."
        imgSrc={imgHero}
      />

      <SectionNewLandingIntro
        title="Why Dental Experts use 831(b)?"
        descriptionOne="Let's cover some of the risks that are facing the dental industry today. In this current environment, we see shrinking margins due to rising overhead cost reductions to healthcare benefits, and increased competition. We also see dental practices offering limited warranties that are not formally defined, nor being positioned as a client benefit. They are simply implied or expected from the patient. Finding meaningful ways to retain your existing clients is always a top priority, but it's becoming more and more difficult. The SRA Dental Protection Plan was designed with this in mind and provides practices with a way to strengthen their overall financial wellbeing."
        descriptionTwo="In the past, these types of solutions were only afforded to large enterprises. For example, large auto dealerships have offered extended warranties for many years and used those profits to help support their business when needed. That has given them financial strength that's unparalleled than other industries. At SRA, we've developed a dental protection plan that allows practices of all sizes to utilize the same concepts in the dental industry. The benefits of the dental protection plan include setting clearly defined patient expectations, adding value, and the ability to customize it to fit the unique needs of each practice. By setting aside a percentage of profits, you can satisfy the terms of your warranty in a more efficient manner. Low claims years allow practices to build an additional asset which can strengthen the overall financial health of the business. SRA provides a turnkey warranty solution that is easy to implement into your practice."
        videoID="637238106"
      />

      <SectionNewLandingBenefits
        title="Benefits of SRA 831(b) Dental Protection Plan"
        benefitTitleOne="Risk Management"
        benefitDescOne="An 831(b) plan can help dentists manage both tangible and intangible risks, such as equipment failure or malpractice claims, providing a comprehensive risk management solution."
        benefitTitleTwo="Business Interruption Protection"
        benefitDescTwo="In the face of unexpected events like pandemics or natural disasters, an 831(b) plan can offer protection against business interruptions, ensuring continuity of practice."
        benefitTitleThree="Mitigation of Contractual Liabilities"
        benefitDescThree="Dentists often engage in contracts with vendors, employees, and other third parties. An 831(b) plan can mitigate the risks associated with these contractual obligations, protecting the practice's financial health."
        benefitTitleFour="Tax-Deferred Benefits"
        benefitDescFour="By mitigating risks through tax-deferred dollars, an 831(b) plan can provide dentists with potential tax advantages, aiding in financial planning and wealth management."
        benefitTitleFive="Flexibility and Customization"
        benefitDescFive="Unlike traditional insurance, an 831(b) plan can be tailored to the specific needs and risks of a dental practice, offering more targeted and effective coverage."
      />
    </LayoutLanding>
  )
}

export default PageDentalLanding
