import React from 'react'
import { Link } from 'gatsby'
import { VimeoPlayer } from 'reactjs-vimeo-player'
import { StaticImage } from "gatsby-plugin-image"

const pStyle = "text-xl mb-6"

const SectionNewLandingIntro = ({ title, descriptionOne, descriptionTwo, videoID }) => (
  <div className="relative">
    <div className="bg-white pt-16 md:pt-24 pb-12 md:pb-24 px-4">
      <div className="w-11/12 md:w-10/12 max-w-screen-xl flex flex-col xl:flex-row items-center mx-auto space-x-0 xl:space-x-8">
        <div className="relative w-full">
          <div>
            <h2 className="text-site-gray-dark text-4xl md:text-5xl font-extrabold text-center tracking-wider mb-8">{title ? title : "Section Title Here"}</h2>

            <div className='grid grid-cols-1 xl:grid-cols-2 xl:gap-16'>
              <p className={pStyle}>{descriptionOne ? descriptionOne : "Section description one here..."}</p>
              <p className={pStyle}>{descriptionTwo ? descriptionTwo : "Section description two here..."}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="relative w-full xl:w-1/2 mx-auto mt-8 z-20 border border-gray-200">
        <VimeoPlayer id={videoID ? videoID : "553073290"} width={640} height={480} responsive />

        <div className="hidden absolute left-0 bottom-0 -ml-14 mb-6 xl:flex justify-center -z-10">
          <StaticImage
            src="../../images/icons/dots.png"
            width={180}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="icon"
            className="w-2/3 md:w-full mx-auto"
          />
        </div>
      </div>

      <div className='mt-16 mb-0 md:mb-4'>
        <div className="hidden xl:flex flex-col items-center justify-center">
          <Link to="/qualify">
            <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-20 py-3">
              <span className="default-text">Do You Qualify?</span>
              <span className="hover-text mx-7">Begin Now</span>
            </button>
          </Link>
        </div>
        
        <div className="xl:hidden flex flex-col items-center justify-center">                    
          <div className="mb-4">
            <p className="text-site-gray-dark text-2xl font-semibold text-center tracking-wider mb-6">Do You Qualify?</p>
              <Link to="/qualify">
              <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3">
                Begin Now
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default SectionNewLandingIntro