import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledDiv = styled.div`
  display: grid;
  background: linear-gradient(90deg, rgba(4, 13, 18, 0.90) 17.38%, rgba(4, 13, 18, 0.00) 56.17%);
  min-height: 400px;
`
const StyledDiv2 = styled.div`
  grid-area: 1/1;
  position: relative;
  place-items: left;
  display: grid;

  @media (max-width: 767px) {
    place-items: center;
  }

  .text-shadow {
    text-shadow: 0 3px 3px rgba(0,0,0,.6);
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  grid-area: 1/1;
  object-fit: cover;
  opacity: none;
  width: 100%;
  height: 1000px;

  @media (max-width: 767px) {
    height: 600px;
  }
`

const HeroSection831bNewLanding = ({ imgSrc, title, subTitle }) => {
  const data = useStaticQuery(graphql`
    query HeroSection831bNewLandingQuery {
      imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgDefault = getImage(data.imgDefault)

  return (
    <>
      <StyledDiv className="relative pt-14 xl:pt-20">
        <StyledGatsbyImage
          alt="hero image"
          image={imgSrc ? imgSrc : imgDefault}
          formats={["auto", "webp", "avif"]}
          className="-z-10"
        />

        <StyledDiv2>
          {title
            ?
            <div className="w-11/12 lg:w-10/12 max-w-screen-xl flex flex-col items-start justify-center mx-auto">
              <div className="w-full md:w-7/12 xl:w-7/12 flex flex-col items-center md:items-start justify-center md:justify-start">
                <h2 className="text-white text-4xl lg:text-6xl text-shadow font-extrabold text-center md:text-left mt-4">
                  {title}
                </h2>

                <h3 className="md:max-w-xl text-xl text-white lg:text-2xl text-shadow font-bold text-center md:text-left mt-6">
                  {subTitle}
                </h3>

                <div className="hidden xl:flex flex-col items-center justify-center mt-8">
                  <Link to="/qualify">
                    <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-20 py-3 mb-6">
                      <span className="default-text">Do You Qualify?</span>
                      <span className="hover-text mx-7">Begin Now</span>
                    </button>
                  </Link>
                </div>
                
                <div className="xl:hidden flex flex-col items-center justify-center mt-6">                    
                  <div className="mb-6">
                    <p className="text-white text-2xl font-semibold text-center tracking-wider mb-6">Do You Qualify?</p>
                      <Link to="/qualify">
                      <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3">
                        Begin Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            :
            null
          }
        </StyledDiv2>
      </StyledDiv>
    </>
  )
}

export default HeroSection831bNewLanding
