import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

const pStyle2 = "text-base md:text-lg text-center mb-6"

const SectionNewLandingBenefits = ({ title, benefitTitleOne, benefitDescOne, benefitTitleTwo, benefitDescTwo, benefitTitleThree, benefitDescThree, benefitTitleFour, benefitDescFour, benefitTitleFive, benefitDescFive }) => (
  <div className="bg-site-gray-lighter pt-12 md:pt-24 pb-12 md:pb-24 px-4">
    <div className="w-11/12 md:w-8/12 xl:w-10/12 max-w-screen-xl flex flex-col items-center mx-auto">

      <h2 className="text-site-gray-dark text-4xl md:text-5xl text-center font-extrabold tracking-wider">{title ? title : "Section Title Here"}</h2>

      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-12 mt-12 mb-8">

        <div className='bg-white p-6 rounded-3xl shadow-xl'>
          <div className="relative flex flex-col items-center w-11/12 mx-auto">
            <div className="relative mb-6">
            <StaticImage
                src="../../images/icons/checklist-icon.png"
                width={90}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="icon"
              />
            </div>
            <div className="w-full max-w-xl mx-auto">
              <h3 className="text-site-red text-xl md:text-2xl font-bold text-center mb-6">{benefitTitleOne ? benefitTitleOne : "Benefit Title One Here"}</h3>
              <p className={pStyle2}>{benefitDescOne ? benefitDescOne : "Benefit description one here..."}</p>
            </div>
          </div>
        </div>

        <div className='bg-white p-6 rounded-3xl shadow-xl'>
          <div className="relative flex flex-col items-center w-11/12 mx-auto">
            <div className="relative mb-6">
              <StaticImage
                src="../../images/icons/checklist-icon.png"
                width={90}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="icon"
              />
            </div>
            <div className="w-full max-w-xl mx-auto">
              <h3 className="text-site-red text-xl md:text-2xl font-bold text-center mb-6">{benefitTitleThree ? benefitTitleThree : "Benefit Title Two Here"}</h3>
              <p className={pStyle2}>{benefitDescThree ? benefitDescThree : "Benefit description two here..."}</p>
            </div>
          </div>
        </div>

        <div className='bg-white p-6 rounded-3xl shadow-xl'>
          <div className="relative flex flex-col items-center w-11/12 mx-auto">
            <div className="relative mb-6">
              <StaticImage
                src="../../images/icons/checklist-icon.png"
                width={90}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="icon"
              />
            </div>
            <div className="w-full max-w-xl mx-auto">
              <h3 className="text-site-red text-xl md:text-2xl font-bold text-center mb-6">{benefitTitleTwo ? benefitTitleTwo : "Benefit Title Three Here"}</h3>
              <p className={pStyle2}>{benefitDescTwo ? benefitDescTwo : "Benefit description three here..."}</p>
            </div>
          </div>
        </div>

        <div className='bg-white p-6 rounded-3xl shadow-xl'>
          <div className="relative flex flex-col items-center w-11/12 mx-auto">
            <div className="relative mb-6">
              <StaticImage
                src="../../images/icons/checklist-icon.png"
                width={90}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="icon"
              />
            </div>
            <div className="w-full max-w-xl mx-auto">
              <h3 className="text-site-red text-xl md:text-2xl font-bold text-center mb-6">{benefitTitleFour ? benefitTitleFour : "Benefit Title Four Here"}</h3>
              <p className={pStyle2}>{benefitDescFour ? benefitDescFour : "Benefit description four here..."}</p>
            </div>
          </div>
        </div>

        <div className='bg-white p-6 rounded-3xl shadow-xl'>
          <div className="relative flex flex-col items-center w-11/12 mx-auto">
            <div className="relative mb-6">
              <StaticImage
                src="../../images/icons/checklist-icon.png"
                width={90}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="icon"
              />
            </div>
            <div className="w-full max-w-xl mx-auto">
              <h3 className="text-site-red text-xl md:text-2xl font-bold text-center mb-6">{benefitTitleFive ? benefitTitleFive : "Benefit Title Five Here"}</h3>
              <p className={pStyle2}>{benefitDescFive ? benefitDescFive : "Benefit description five here..."}</p>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-16 mb-0 md:mb-4'>
        <div className="hidden xl:flex flex-col items-start justify-start">
          <Link to="/qualify">
            <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-20 py-3">
              <span className="default-text">Do You Qualify?</span>
              <span className="hover-text mx-7">Begin Now</span>
            </button>
          </Link>
        </div>
        
        <div className="xl:hidden flex flex-col items-center justify-center">                    
          <div className="mb-4">
            <p className="text-site-gray-dark text-2xl font-semibold text-center tracking-wider mb-6">Do You Qualify?</p>
              <Link to="/qualify">
              <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3">
                Begin Now
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default SectionNewLandingBenefits