import React from "react"
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'

import LogoSimple from "../logo-simple"
import MenuItemsLandingUpdate from "./menu-items-landing-update"

const MobileDrawerLanding = loadable(() =>
  pMinDelay(import('./mobile-drawer-landing'), 2000)
)

const HeaderLanding = () => {
  return (
    <div className="w-full bg-site-gray-dark pt-0 lg:pt-0">
      <header
        className="bg-site-black fixed w-full z-50 border-b-2 border-gray-500"
      >
        <nav className="w-11/12 max-w-screen-xl flex items-center justify-between mx-auto pt-2 pb-2">
          <div className="w-24 lg:w-36">
            <LogoSimple />
          </div>

          <MenuItemsLandingUpdate />

          <MobileDrawerLanding />
        </nav>
      </header>
    </div>
  )
}

export default HeaderLanding
